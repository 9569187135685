export const advCategories = {
	no_category: 'Не указано',
	car_licence_plates: 'Автомобильные номера и их дубликаты',
	car_insurance: 'Автострахование',
	gambling: 'Азартные игры',
	tobacco_accessories: 'Аксессуары для табака',
	alcohol_products: 'Алкогольная продукция',
	pharmacies_and_medicines: 'Аптеки и лекарства',
	banking_services_and_loans: 'Банковские услуги, кредиты',
	binary_options: 'Бинарные опционы',
	biologically_active_additives: 'Биологически активные добавки (БАД)',
	charitable_organizations: 'Благотворительные организации',
	ad_blockers: 'Блокировщики рекламы',
	bookmaker_activity: 'Букмекерская деятельность',
	credit_bureau: 'Бюро кредитных историй',
	veterinary_pharmacies: 'Ветеринарные аптеки и препараты',
	adult_content: 'Взрослый контент',
	government_bodies: 'Государственные органы и их услуги',
	delicate_topics: 'Деликатные тематики',
	baby_food: 'Детское питание',
	remote_sale_of_prohibited_goods:
		'Дистанционная продажа запрещенных товаров',
	remote_sale_of_goods_and_services: 'Дистанционная продажа товаров и услуг',
	loans: 'Займы',
	loans_secured_by_real_estate: 'Займы под залог недвижимости',
	maternity_capital: 'Материнский капитал',
	earnings: 'Заработок',
	products_similar_to_weapons: 'Изделия, конструктивно сходные с оружием',
	investing: 'Инвестирование',
	books_and_videos_about_medicine: 'Книги, видео, сайты о медицине и лечении',
	debt_collection: 'Коллекторство',
	cosmetology_products: 'Косметологические средства и ингредиенты',
	cosmetology_services: 'Косметологические услуги',
	copies_of_original_goods: 'Копии оригинальных товаров',
	credit_cooperatives: 'Кредитные кооперативы',
	cryptocurrency: 'Криптовалюта',
	leasing: 'Лизинг',
	pawnshops: 'Ломбарды',
	lotteries: 'Лотереи',
	lottery_and_gaming_equipment: 'Лотерейное и игровое оборудование',
	mediation: 'Медиация',
	medical_products: 'Медицинские изделия',
	medical_acids_and_gases: 'Медицинские кислоты, газы',
	medical_services: 'Медицинские услуги',
	medical_services_online: 'Медицинские услуги онлайн',
	microfinance_organisations: 'Микрофинансовые организации',
	cheating_internet_counters: 'Накрутка интернет-счетчиков',
	psychotropic_substances:
		'Наркотические и психотропные вещества, их прекурсоры',
	folk_medicine: 'Народная медицина, целительство',
	non_medical_gases_and_acids: 'Немедицинские газы и кислоты',
	new_buildings: 'Новостройки и долевое строительство',
	weapons_training: 'Обучение обращению с оружием',
	weapon: 'Оружие',
	official_service_centers: 'Официальные сервисные центры',
	payment_systems: 'Платежные системы и переводы денежных средств',
	paid_subscriptions: 'Платные подписки',
	political_advertising: 'Политическая реклама',
	offers_of_intermediaries_in_russia:
		'Предложения посредников в России и финансовые услуги за рубежом',
	termination_of_pregnancy: 'Прерывание беременности, аборты',
	sale_of_goods_credit: 'Продажа товаров и услуг в кредит',
	prescription_psychotropic_drugs:
		'Рецептурные препараты и лекарства с наркотическими или психотропными веществами',
	realtor_services: 'Риелторские услуги, продажа недвижимости',
	social_advertising: 'Социальная реклама',
	sports_nutrition: 'Спортивное питание',
	stimulating_events: 'Cтимулирующие мероприятия',
	insurance_services: 'Страховые услуги',
	tobacco_products: 'Табачные изделия',
	tragic_content: 'Трагический контент',
	services_for_motorists: 'Услуги для автомобилистов',
	securities_market_services: 'Услуги на рынке ценных бумаг',
	certification_services: 'Услуги по сертификации',
	forex: 'Форекс',
	futures: 'Фьючерсы',
	esoterica_and_magic: 'Эзотерика и магия',
	jewellery: 'Ювелирные изделия',
} as const

export type AdvCategoriesKeys = keyof typeof advCategories
export type AdvCategoriesValues = typeof advCategories[AdvCategoriesKeys]
