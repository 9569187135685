import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { ReactComponent as DocumentIcon } from 'src/assets/images/document.svg'
import s from './reportCard.module.scss'
import { ReactComponent as XLSIcon } from 'src/assets/images/xls.svg'
import { ReactComponent as DocsPreview } from 'src/assets/images/doc-preview.svg'
import useCampaignData from 'src/stores/campaignData'
import { useHistory } from 'react-router-dom'

export const CampaignReportCard = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()
	const history = useHistory()

	const handleWatchReportClick = () => {
		history.push(`/campaigns/${campaign.id}/report`)
	}

	return (
		<Card className={s.reportCard}>
			<div className={s.reportContent}>
				<div className={s.title}>
					<DocumentIcon />
					<h5>Отчет о завершенной рекламной кампании</h5>
				</div>

				<div className={s.actions}>
					<Button variant="light">
						<XLSIcon />
						<h6>Скачать в Excel</h6>
					</Button>
					<Button
						variant="light"
						onClick={() => handleWatchReportClick()}
					>
						<DocsPreview />
						<h6>Смотреть</h6>
					</Button>
				</div>
			</div>
		</Card>
	)
}
