import { FormikErrors } from 'formik'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { ProviderCampaignReportFormValues } from './CompletedCampaignReport'

type Props = {
	errors: FormikErrors<ProviderCampaignReportFormValues>
	values: { forecastCost: number; actualCost: number }
	setFieldValue: (field: 'actualCost' | 'forecastCost', value: number) => void
}
export const CampaignAmountReport = ({
	errors,
	setFieldValue,
	values,
}: Props) => {
	const handleChange = (
		value: string,
		field: 'actualCost' | 'forecastCost'
	) => {
		const clearStringValue = value.trim().replaceAll(' ', '')
		const numberedValue = parseFloat(clearStringValue)

		setFieldValue(field, numberedValue)
	}

	return (
		<div className="d-flex flex-column gap-3">
			<h5>Стоимость, без НДС</h5>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Прогнозная</Form.Label>
					<NumericFormat
						id="campaignBudget"
						decimalScale={2}
						value={values.forecastCost}
						suffix=" ₽"
						thousandSeparator={' '}
						customInput={InputWithEndText}
						onValueChange={(values) =>
							handleChange(values.value, 'forecastCost')
						}
						size={'lg'}
						placeholder={'Не заполнено'}
						isInvalid={!!errors.forecastCost}
						controlStyle={{ borderRadius: '16px' }}
						disabled={true}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.forecastCost}
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group as={Col}>
					<Form.Label>Фактическая</Form.Label>
					<NumericFormat
						id="campaignBudget"
						decimalScale={2}
						value={values.actualCost}
						suffix=" ₽"
						thousandSeparator={' '}
						customInput={InputWithEndText}
						onValueChange={(values) =>
							handleChange(values.value, 'actualCost')
						}
						size={'lg'}
						placeholder={'Не заполнено'}
						isInvalid={!!errors.actualCost}
						controlStyle={{ borderRadius: '16px' }}
					/>
					<Form.Control.Feedback type="invalid">
						{errors.actualCost}
					</Form.Control.Feedback>
				</Form.Group>
			</Row>
		</div>
	)
}
