import React from 'react'
import { cn, STEPS } from '../../CreateCampaignPage'
import useResponsive from 'src/utils/useResponsive'
import { AdvertisingFormatsList } from './AdvertisingFormatsList'

export const Stepper = ({ step, avaliableStep, onStepClick }) => {
	const { isDesktop, isMobile } = useResponsive()

	return (
		<div
			id={'create-campaign-stepper-wpapper'}
			className="d-flex flex-column"
		>
			<ol
				className={cn('stepper')}
				id={'create-campaign-stepper'}
				style={{
					display: 'flex',
					flexDirection: isMobile ? 'column' : 'column-reverse',
					alignItems: isMobile ? 'center' : 'flex-start',
				}}
			>
				{/* блок активных шагов*/}
				<div className={cn('stepper-block')} id={'stepper-block'}>
					{STEPS.map(({ label }, i) => (
						<li
							className={`stepper-block-step-${i + 1}`}
							key={label}
							onClick={
								i <= avaliableStep
									? () => onStepClick(i)
									: () => {}
							}
						>
							<div
								className={cn('step', {
									active: step === i,
									completed: step > i,
									avaliable: true,
								})}
							>
								<div className={cn('step-num')}>
									{step > i ? (
										<i className="bi bi-check2" />
									) : (
										i + 1
									)}
								</div>
								<div className={cn('step-label')}>
									{!isMobile ? label : ''}
								</div>
							</div>

							{label === 'Форматы рекламы' &&
								isDesktop &&
								step === i && <AdvertisingFormatsList />}
						</li>
					))}
				</div>
			</ol>
		</div>
	)
}
