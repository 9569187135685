import React, { useState } from 'react'
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap'
import { BookingDetails } from 'src/stores/ProviderTypes.types'
import useProviderData from 'src/stores/providerData'
import { useQueryParams } from 'src/utils'
import { ProviderBookingQueryParams } from '../../../filters/booking-filters-mobile/BookingsSettings'
import { BookingOperationButtonsActions } from '../../BookingOperationsButtons'
import { CompletedCampaignReport } from './completed-campaign-report/CompletedCampaignReport'

type Props = {
	actionType: BookingOperationButtonsActions
	booking: BookingDetails
	open: boolean
	onClose: () => void
}

const ModalTextContent: Record<
	BookingOperationButtonsActions,
	{
		title: string
		text: string
		cancelButtonText: string
		submitButtonText: string
		state: 'confirm' | 'reject' | 'adjustment' | undefined
	}
> = {
	accept: {
		title: 'Подтверждение брони',
		cancelButtonText: 'Отменить',
		submitButtonText: 'Да, подтверждаю',
		text: 'Вы уверены, что хотите подтвердить бронь?',
		state: 'confirm',
	},
	download: {
		cancelButtonText: '',
		submitButtonText: '',
		text: '',
		title: '',
		state: undefined,
	},
	edit: {
		cancelButtonText: 'Отменить',
		submitButtonText: 'Отправить',
		text: 'Напишите, что необходимо исправить заказчику, чтобы пройти модерацию',
		title: 'Исправление брони',
		state: 'adjustment',
	},
	reject: {
		cancelButtonText: 'Отменить',
		submitButtonText: 'Отправить',
		text: 'Напишите заказчику причину, почему отклоняете бронь',
		title: 'Отклонение брони',
		state: 'reject',
	},
	'accept-report': {
		cancelButtonText: 'Отменить',
		submitButtonText: 'Подвердить',
		text: 'Напишите заказчику причину, почему отклоняете бронь',
		title: 'Создание отчета',
		state: 'reject',
	},
}

const minLength = 5
export const ActionModal = ({ actionType, booking, onClose, open }: Props) => {
	const { params } = useQueryParams<ProviderBookingQueryParams>()

	const [isLoading, setIsLoading] = useState(false)
	const [provider, providerInterface] = useProviderData()
	const [adjustmentMessage, setAdjustmentMessage] = useState(() => {
		if (actionType === 'reject') return booking.reject_msg
		else return booking.adjustment_msg
	})
	const [isDocsRequired, setIsDocsRequired] = useState(
		booking.is_docs_required
	)

	const handleSubmit = async () => {
		setIsLoading(true)
		const body = {
			state: ModalTextContent[actionType].state,
			is_docs_required: isDocsRequired,
		}
		if (actionType === 'reject') {
			Object.assign(body, { reject_msg: adjustmentMessage })
		}
		if (actionType === 'edit') {
			Object.assign(body, { adjustment_msg: adjustmentMessage })
		}
		await providerInterface.patchProviderBooking(booking.id, body)
		await providerInterface.fetchBookings(
			params?.state || 'all',
			params?.limit,
			params?.offset,
			params.adv_companies,
			params?.company,
			'',
			params?.ordering
		)
		setIsLoading(false)
		onClose()
	}

	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					{ModalTextContent[actionType].title}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body
				className={'px-0'}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<ActionModalContent
					booking={booking}
					onClose={onClose}
					setAdjustmentMessage={setAdjustmentMessage}
					adjustmentMessage={adjustmentMessage}
					isDocRequired={isDocsRequired}
					setIsDocRequired={setIsDocsRequired}
					text={ModalTextContent[actionType].text}
					actionType={actionType}
				/>
			</Modal.Body>
			{actionType !== 'accept-report' && (
				<Modal.Footer>
					<Button variant="secondary" onClick={onClose}>
						{ModalTextContent[actionType].cancelButtonText}
					</Button>
					<Button
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
						}}
						disabled={
							(actionType !== 'download' &&
								actionType !== 'accept' &&
								adjustmentMessage?.length < minLength) ||
							isLoading
						}
						variant="primary"
						onClick={handleSubmit}
					>
						Отправить
						{isLoading && <Spinner animation="border" size="sm" />}
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	)
}

type ActionModalContentProps = {
	actionType: 'accept' | 'edit' | 'reject' | 'download' | 'accept-report'
	text: string
	setAdjustmentMessage: (value: string) => void
	adjustmentMessage: string
	isDocRequired: boolean
	setIsDocRequired: React.Dispatch<React.SetStateAction<boolean>>
	booking: BookingDetails
	onClose: () => void
}

export const ActionModalContent = ({
	actionType,
	text,
	adjustmentMessage,
	isDocRequired,
	setAdjustmentMessage,
	setIsDocRequired,
	booking,
	onClose,
}: ActionModalContentProps) => {
	const [error, setError] = useState<string | undefined>(() => {
		if (adjustmentMessage?.length < 5) {
			return `Минимальная длина сообщения: ${minLength} символов`
		}
		return undefined
	})

	if (actionType === 'accept') {
		return <h6>{text}</h6>
	}

	const handleAdjustmentMessageChange = (value: string) => {
		if (value.length < 5) {
			setError(`Минимальная длина сообщения: ${minLength} символов`)
			setAdjustmentMessage(value)
		} else {
			setError(undefined)
			setAdjustmentMessage(value)
		}
	}

	if (actionType === 'edit' || actionType === 'reject') {
		return (
			<div
				style={{
					padding: '0 24px',
					display: 'flex',
					flexDirection: 'column',
					gap: '24px',
				}}
			>
				<InputGroup hasValidation className="mb-0">
					<Form.Label>{text}</Form.Label>
					<Form.Control
						placeholder="Ваш комментарий"
						size={'lg'}
						value={adjustmentMessage}
						onChange={(e) => {
							handleAdjustmentMessageChange(e.target.value)
						}}
						name={`text`}
						as={'textarea'}
						isInvalid={!!error}
					/>
					<Form.Control.Feedback type="invalid">
						{error}
					</Form.Control.Feedback>
				</InputGroup>

				<Form.Check
					className="mb-0"
					checked={isDocRequired}
					onChange={(e) => setIsDocRequired(!isDocRequired)}
					type={'checkbox'}
					label={'Требуются дополнительные документы'}
					id={`is-doc-required`}
				/>
			</div>
		)
	}
	if (actionType === 'accept-report') {
		return <CompletedCampaignReport booking={booking} onClose={onClose} />
	}
	return <div></div>
}
