import { Formik, FormikHelpers } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { BookingDetails, ProviderStatLog } from 'src/stores/ProviderTypes.types'

import { CampaignAmountReport } from './CampaignAmountReport'
import { CampaignPhotoReport } from './CampaignPhotoReport'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL, providerBookingInfo } from 'src/constants/api'
import { CampaignViewsReportTable } from './campaign-views-report-table/CampaignViewsReportTable'
import { ProviderComment } from './ProviderComment'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

export type ProviderCampaignViewsData = {
	date: string
	forecast: number
	actual: number
}

export type ProviderCampaignReportFormValues = {
	forecastCost: number
	actualCost: number
	statistics: ProviderCampaignViewsData[]
	provider_comment: string
}

export type ProviderBookingPhotoReport = Pick<
	ProviderStatLog,
	'date' | 'photo_reports'
>

type PatchViewsData = {
	provider_fact_cost: number
	views_count_data: {
		date: string
		views_count: number
	}[]
	provider_comment: string
}

const patchBookingViews = async (bookingId: number, data: PatchViewsData) => {
	await authFetch({
		url: `${BASE_URL}/provider/bookings/${bookingId}/`,
		method: 'PATCH',
		body: data,
	})
}

type Props = {
	booking: BookingDetails
	onClose: () => void
}
export const CompletedCampaignReport = ({ booking, onClose }: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const [bookingDetail, setBookingDetail] = useState(booking)

	const { addToast } = useContext(ToastsContext)

	const refetchBookingDetail = async () => {
		const res = await authFetch<BookingDetails>({
			url: providerBookingInfo.params(booking?.id),
			method: 'GET',
		})
		setBookingDetail(res)
	}
	const initializeBookingDetail = async () => {
		setIsLoading(true)
		refetchBookingDetail()
		setIsLoading(false)
	}
	const photos = useMemo(() => {
		return bookingDetail.stat_log.map((stat) => {
			return {
				date: stat.date,
				photo_reports: stat.photo_reports,
			} as ProviderBookingPhotoReport
		})
	}, [bookingDetail])

	const initFormData: ProviderCampaignReportFormValues = useMemo(
		() => ({
			actualCost: parseFloat(bookingDetail.provider_fact_cost || '0'),
			forecastCost: bookingDetail.booking_budget,
			statistics: bookingDetail.stat_log.map((stat) => ({
				actual: stat.views_count,
				forecast: stat.forecast_views_count,
				date: stat.date,
			})),
			provider_comment: bookingDetail.provider_comment || '',
		}),
		[bookingDetail]
	)
	const handleSubmit = async (values: ProviderCampaignReportFormValues) => {
		const patchData: PatchViewsData = {
			provider_fact_cost: values.actualCost,
			views_count_data: values.statistics.map((stat) => ({
				date: stat.date,
				views_count: stat.actual,
			})),
			provider_comment: values.provider_comment || '',
		}

		try {
			await patchBookingViews(booking.id, patchData)
			addToast({
				text: `Отчет сформирован`,
				type: 'success',
			})
			onClose()
		} catch (e) {
			console.log(e)
		} finally {
			setIsLoading(false)
		}
	}
	useEffect(() => {
		initializeBookingDetail()
	}, [])
	return (
		<Formik<ProviderCampaignReportFormValues>
			initialValues={initFormData}
			onSubmit={handleSubmit}
			validateOnChange={false}
			enableReinitialize
		>
			{({ values, setFieldValue, handleSubmit, errors }) => (
				<div>
					<div className="d-flex flex-column gap-4 p-4 pt-0">
						{isLoading ? (
							<div className="d-flex justify-content-center align-items-center">
								<Spinner animation="border" variant="primary" />
							</div>
						) : (
							<>
								<CampaignAmountReport
									errors={errors}
									values={values}
									setFieldValue={setFieldValue}
								/>
								<CampaignPhotoReport
									refetchBookingDetail={refetchBookingDetail}
									bookingId={bookingDetail.id}
									photos={photos}
									startDate={
										booking.advertising_company.start_date
									}
									endDate={
										booking.advertising_company.end_date
									}
								/>
								<CampaignViewsReportTable
									statistics={values.statistics}
									setFieldValue={setFieldValue}
								/>

								<ProviderComment
									setFieldValue={setFieldValue}
									value={values.provider_comment}
								/>
							</>
						)}
					</div>
					<div
						style={{
							borderTop: '1px solid #CED4DA',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
							gap: '8px',
							paddingTop: '16px',
							paddingRight: '16px',
						}}
					>
						<Button variant="secondary" onClick={onClose}>
							Отменить
						</Button>
						<Button
							onClick={() => handleSubmit()}
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '8px',
							}}
							disabled={isLoading}
							variant="primary"
							type={'submit'}
						>
							Подтвердить
							{isLoading && (
								<Spinner animation="border" size="sm" />
							)}
						</Button>
					</div>
				</div>
			)}
		</Formik>
	)
}
