import React from 'react'
import { useTable, Column, Cell } from 'react-table'
import { ProviderCampaignViewsData } from '../CompletedCampaignReport'
import { ProviderViewsEditableTableCell } from './provider-views-table-cell/ProviderViewsEditableTableCell'
import s from './viewsReportTable.module.scss'
import moment from 'moment'

export type CampaignViewsReportTableData = {
	date: string
	views: {
		forecast: number
		actual: number
	}
}
type EditableCellProps = {
	value: any
	cell: Cell<CampaignViewsReportTableData, any>
	onCellChange?: (
		cell: Cell<CampaignViewsReportTableData, any>,
		value: number
	) => void
	onAllColumnValuesChange?: (
		columnId: 'forecast' | 'actual',
		value: number
	) => void
}
// Определение колонок таблицы
const columns: Column<CampaignViewsReportTableData>[] = [
	{
		Header: 'Дата',
		accessor: 'date', // Колонка для даты
		minWidth: 160,
		Cell: (props) => (
			<td
				tabIndex={0}
				{...props.cell.getCellProps()}
				style={{
					maxHeight: '48px',
					overflow: 'hidden',
					minWidth: props.column?.minWidth,
				}}
			>
				<div
					style={{ height: '100%', padding: '12px 16px' }}
					className="d-flex justify-content-between"
				>
					<span>
						{moment(props.value, 'YYYY-MM-DD').format('D MMMM')}
					</span>
				</div>
			</td>
		),
	},
	{
		Header: 'Показы',
		columns: [
			{
				id: 'forecast',
				Header: 'Прогнозные',
				accessor: (row) => row.views.forecast,
				Cell: ({
					value,
					cell,
					onCellChange,
					onAllColumnValuesChange,
				}: EditableCellProps) => (
					<ProviderViewsEditableTableCell
						isEditable={false}
						cell={cell}
						value={value}
						onCellChange={onCellChange}
						onAllColumnValuesChange={onAllColumnValuesChange}
					/>
				),
			},
			{
				id: 'actual',
				Header: 'Фактические',
				accessor: (row) => row.views.actual,
				Cell: ({
					value,
					cell,
					onAllColumnValuesChange,
					onCellChange,
				}: EditableCellProps) => (
					<ProviderViewsEditableTableCell
						isEditable={true}
						cell={cell}
						value={value}
						onCellChange={onCellChange}
						onAllColumnValuesChange={onAllColumnValuesChange}
					/>
				),
			},
		],
	},
]

type Props = {
	statistics: ProviderCampaignViewsData[]
	setFieldValue: (
		field: 'statistics',
		value: ProviderCampaignViewsData[],
		shouldValidate?: boolean
	) => void
}
// Основной компонент таблицы
export const CampaignViewsReportTable = ({
	statistics,
	setFieldValue,
}: Props) => {
	const data: CampaignViewsReportTableData[] = statistics.map((item) => ({
		date: item.date,
		views: {
			forecast: item.forecast,
			actual: item.actual,
		},
	}))

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data,
		})

	const handleCellChange = (
		cell: Cell<CampaignViewsReportTableData, any>,
		value: number
	) => {
		const updatedStatistics = statistics.map((stat) => {
			if (stat.date === cell.row.original.date) {
				return { ...stat, [cell.column.id]: value }
			}
			return stat
		})
		setFieldValue('statistics', updatedStatistics)
	}

	const handleAllColumnValuesChange = (
		columnId: 'forecast' | 'actual',
		value: number
	) => {
		const updatedStatistics = statistics.map((stat) => ({
			...stat,
			[columnId]: value,
		}))
		setFieldValue('statistics', updatedStatistics)
	}

	return (
		<div className="d-flex flex-column gap-3">
			<h5 className="m-0">Статистика показов по дням</h5>

			<table {...getTableProps()} className={s.viewsReportTable}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={headerGroup.id}
						>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps()}
									key={column.id}
								>
									{
										// @ts-ignore
										column.originalId !==
										'date_placeholder' ? (
											<div>
												<h6 className="m-0">
													{column.render('Header')}
												</h6>
											</div>
										) : (
											<div>
												<h6
													className={'m-0'}
													style={{
														fontWeight: 500,
														fontSize: '16px',
													}}
												>
													Дата
												</h6>
											</div>
										)
									}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row)
						return (
							<tr
								{...row.getRowProps()}
								key={row.id}
								style={{ maxHeight: '48px' }}
							>
								{row.cells.map((cell) => {
									const renderCell = cell.render('Cell', {
										cell,
										onCellChange: handleCellChange,
										onAllColumnValuesChange:
											handleAllColumnValuesChange,
									})
									return renderCell
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
